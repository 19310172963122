import { queryOptions } from "@tanstack/react-query";
import { fetcher } from "fetcher!sofe";
import {
  TClientPreview,
  TClientPreviewData,
  TClientRecordPreviewData,
  TMigSetting,
  TMigration,
} from "src/migration/migration-types";
import { genQueries } from "src/react-query";

export const crmMigrationQueries = genQueries("crm-migration", ({ genKey }) => ({
  migration: () =>
    queryOptions<TMigration>({
      queryKey: genKey("migration"),
      queryFn: () => fetcher("/api/v2/migration"),
      staleTime: 5 * 60 * 1_000, // 5 minutes
    }),
  migrationPreviewTable: (params: { migrationId?: string; search: string }) =>
    queryOptions<TClientPreview[]>({
      queryKey: genKey("previewTable", params),
      queryFn: () =>
        fetcher(`/api/v2/migration/${params.migrationId}/preview?search=${params.search}`).then(
          (res: TClientPreviewData) => res?.preview
        ),
      enabled: !!params.migrationId,
      staleTime: 15_000,
    }),
  migrationPreviewClient: (params: { migrationId?: string; clientId?: string }) =>
    queryOptions<TClientRecordPreviewData>({
      queryKey: genKey("previewClient", params),
      queryFn: () => fetcher(`/api/v2/migration/${params.migrationId}/preview/${params.clientId}`),
      enabled: !!params.migrationId && !!params.clientId,
      staleTime: 15_000,
    }),
  migrationSettings: () =>
    queryOptions<TMigSetting[]>({
      queryKey: genKey("settings"),
      queryFn: () => fetcher("/api/v2/migration/schema"),
      staleTime: 5 * 60 * 1_000, // 5 minutes
    }),
}));
