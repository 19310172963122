import { queryOptions } from "@tanstack/react-query";
import { genQueries } from "src/react-query";
import { TContact } from "src/common/types";
import { GetContactsParams, getContact, getContacts } from "src/resources/contacts.resource";

export const contactQueries = genQueries("contacts", ({ genKey }) => ({
  contact: (contactId: string) =>
    queryOptions<TContact | Omit<TContact, "id">>({
      queryKey: genKey("contact", { contactId }),
      queryFn: () => getContact(contactId),
      staleTime: 10_000, // 10 seconds
    }),

  contacts: (params: GetContactsParams) =>
    queryOptions<any>({
      queryKey: genKey("contacts", params),
      queryFn: () => getContacts(params),
      staleTime: 10_000, // 10 seconds
    }),
}));
