import { queryOptions } from "@tanstack/react-query";
import { getTags } from "src/client-list/client-list.resource";
import { genQueries } from "src/react-query";

export const tagQueries = genQueries("tags", ({ genKey }) => ({
  tags: () =>
    queryOptions<any>({
      queryKey: genKey("tags", {}),
      queryFn: () => getTags(),
      staleTime: 10000, // 10 secs
    }),
}));
