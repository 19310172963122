import { pluck, map } from "rxjs/operators";
import { sortBy } from "lodash";

import { fetchAsObservable } from "fetcher!sofe";
import { handleError } from "src/error";

export type GetClientsParams = {
  page?: string | number;
  limit?: string | number;
  filters?: any;
  sort_data?: any[];
  search?: string;
  visibleColumns?: string[];
};
export function getClients({
  page = "1",
  limit = "50",
  filters,
  sort_data = [],
  search,
  visibleColumns,
}: GetClientsParams) {
  return fetchAsObservable(`api/v2/clients/search`, {
    method: "POST",
    body: {
      page,
      limit,
      filters,
      sort_data,
      search,
      visible_columns: visibleColumns,
    },
  });
}
export function getClientsPromise(params: GetClientsParams) {
  return new Promise((resolve, reject) => getClients(params).subscribe(resolve, reject));
}

export function getCustomFieldsObs() {
  return fetchAsObservable("/api/custom_fields").pipe(
    pluck("custom_fields"),
    map((fields: any) => {
      return sortBy(fields, (field) => field.field_name.toLowerCase());
    })
  );
}

export function getCustomFields() {
  return new Promise((resolve, reject) => getCustomFieldsObs().subscribe(resolve, reject));
}

export function getClientFilterViews() {
  return new Promise((resolve, reject) => {
    fetchAsObservable(`/api/v2/clients/filters`).subscribe(resolve, reject);
  });
}

export function createFilterView(body: any) {
  return fetchAsObservable(`/api/v2/clients/filters`, {
    method: "POST",
    body: body,
  });
}

export function patchFilterView(viewId: string, body: any) {
  return fetchAsObservable(`/api/v2/clients/filters/${viewId}`, {
    method: "PATCH",
    body: body,
  });
}

export function patchFilterViewOrder(orderedViewIds: string[]) {
  return fetchAsObservable("/api/v2/clients/filters/filter-reorder", {
    method: "PATCH",
    body: {
      ids: orderedViewIds,
    },
  });
}

export function deleteFilterViews(viewIds: string[]) {
  return fetchAsObservable(`/api/v2/clients/filters`, {
    method: "DELETE",
    body: {
      ids: viewIds,
    },
  });
}

// body.filter_data - Filter data used in filter view
// body.includes - Selection of user ids to include
// body.excludes - Selection of users ids to exclude
export function translateFilterDataToJql(body: any) {
  return fetchAsObservable(`/api/v2/clients/translate`, {
    method: "POST",
    body: body,
  });
}

/*
body: {
  filters,
  sort_data,
  search,
  excludes,
  includes
}
*/
export function exportFilterView(body: any) {
  return fetchAsObservable("/api/v2/clients/filters/export", {
    method: "POST",
    body,
  });
}

// https://code.canopy.dev/python/crm-service/-/wikis/CRM-Technical-v2022#bulk-actions
export function patchClients(body: any) {
  return fetchAsObservable("/api/v2/clients", {
    method: "PATCH",
    body,
  });
}

export function getTagsObs() {
  return fetchAsObservable(`/api/tags`).pipe(pluck("tags"));
}

export function getTags() {
  return new Promise((resolve, reject) => getTagsObs().subscribe(resolve, reject));
}

export function createTag(name: string) {
  return fetchAsObservable(`/api/tags`, {
    method: "POST",
    body: {
      tags: [
        {
          name: name,
        },
      ],
    },
  });
}

// userIds - The IDs of all the resulting users that should be set on the client
// sendNotificationsTo - The IDs of NEWLY added users. These users will get a notification
// The notification verbiage was taken from client-menu
export function assignClientTeamMembers({ clientId, userIds, sendNotificationsTo }: any) {
  return fetchAsObservable(`/api/clients/${clientId}/team-members`, {
    method: "PUT",
    body: {
      team_members: userIds,
      ...(!!sendNotificationsTo?.length
        ? {
            notifications: {
              users: sendNotificationsTo,
              messages: {
                team_message:
                  "Hi there. We’ll be using Canopy to share ideas, gather feedback, and track progress for this client.",
              },
            },
          }
        : {}),
    },
  });
}

// Used to fetch the available options for conditional select filters
export function fetchSelectionOptions({ field, search = "", fieldId = "" }: any) {
  const params = new URLSearchParams();
  if (field) params.append("field", field);
  if (search) params.append("search", search);
  if (fieldId) params.append("id", fieldId);
  return new Promise((resolve, reject) => {
    return fetchAsObservable(`/api/v2/clients/filter_values?${params}`).subscribe(
      (result: any) => {
        resolve(result.values);
      },
      (err: Error) => {
        handleError(err);
        reject();
      }
    );
  });
}
