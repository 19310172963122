import { queryOptions } from "@tanstack/react-query";
import { genQueries } from "src/react-query";
import { fetchAsObservable } from "fetcher!sofe";
import { expand, pluck, reduce } from "rxjs/operators";
import { sortBy } from "lodash";
import { EMPTY } from "rxjs";

export function getUsers(onlyActive = true) {
  return new Promise((resolve, reject) =>
    fetchAsObservable(`/wg/users?role=TeamMember&basic=true&onlyActive=${onlyActive}`)
      .pipe(pluck("users"))
      .subscribe((users: any) => {
        resolve(sortBy(users, "name"));
      }, reject)
  );
}

export function getTeamsObs(page = "1") {
  return fetchAsObservable(`/wg/user-teams?limit=100&page=${page}`);
}

export function getAllTeams() {
  return new Promise((resolve, reject) => {
    getTeamsObs()
      .pipe(
        expand((result: any) => {
          const nextPage = result.meta.paginator.next_page;
          return nextPage ? getTeamsObs(nextPage) : EMPTY;
        }),
        reduce((acc, data: any) => acc.concat(data?.teams), [])
      )
      .subscribe(resolve, reject);
  });
}

export const teamMemberQueries = genQueries("team-members", ({ genKey }) => ({
  teamMembers: ({ onlyActive }: { onlyActive: boolean }) =>
    queryOptions<any>({
      // TODO: using these basic keys since comms-ui and global-settings use them. We'll need to create a unified teamMemberQueries in global-settings
      queryKey: ["team-members", { onlyActive }],
      queryFn: () => getUsers(onlyActive),
      staleTime: 10_000, // 10 seconds
    }),

  allTeams: () =>
    queryOptions<any>({
      // TODO: using these basic keys since comms-ui and global-settings use them. We'll need to create a unified teamMemberQueries in global-settings
      queryKey: ["team-members", "teams"],
      queryFn: () => getAllTeams(),
      staleTime: 10_000, // 10 seconds
    }),
}));
