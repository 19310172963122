import { fetchAsObservable } from "fetcher!sofe";
import { TClientGroup } from "src/common/types";

export type GetClientGroupsParams = {
  search?: string;
  strict?: boolean;
  ids?: string[];
  page?: number;
  limit?: string;
};

export function getClientGroups({ search, strict = false, ids, page, limit }: GetClientGroupsParams) {
  const urlParams = new URLSearchParams();
  if (search) urlParams.append("search", search);
  if (ids) urlParams.append("ids", ids.join(","));
  if (page) urlParams.append("page", page.toString());
  if (limit) urlParams.append("limit", limit);
  urlParams.append("strict", strict.toString());
  urlParams.append("includes", "inactive");
  return fetchAsObservable(`/api/client-groups/?${urlParams.toString()}`);
}
export function GetClientGroupsPromise(params: GetClientGroupsParams) {
  return new Promise((resolve, reject) => getClientGroups(params).subscribe(resolve, reject));
}

export function getClientGroupById({ id }: { id: string }) {
  return fetchAsObservable(`/api/client-groups/${id}?includes=inactive`);
}
export function getClientGroupByIdPromise({ id }: { id: string }) {
  return new Promise<TClientGroup>((resolve, reject) => getClientGroupById({ id }).subscribe(resolve, reject));
}

export function putClientGroupObs({ id, name, clients }: { id: string; name: string; clients: string[] }) {
  return fetchAsObservable(`/api/client-groups/${id}`, {
    method: "PUT",
    body: {
      name,
      clients,
    },
  });
}

export function putClientGroup({ id, name, clients }: { id: string; name: string; clients: string[] }) {
  return new Promise<void>((resolve, reject) => putClientGroupObs({ id, name, clients }).subscribe(resolve, reject));
}

export function patchClientGroup({ id, clients_to_add }: { id: string; clients_to_add: string[] }) {
  return fetchAsObservable(`/api/client-groups/${id}`, {
    method: "PATCH",
    body: {
      clients_to_add,
    },
  });
}

export function deleteClientGroup({ id }: { id: string }) {
  return fetchAsObservable(`/api/client-groups/${id}`, {
    method: "DELETE",
  });
}

export function postClientGroupObs({ name, clients }: { name: string; clients: string[] }) {
  return fetchAsObservable(`/api/client-groups/`, {
    method: "POST",
    body: {
      name,
      clients,
    },
  });
}

export function postClientGroup({ name, clients }: { name: string; clients: string[] }) {
  return new Promise<void>((resolve, reject) => postClientGroupObs({ name, clients }).subscribe(resolve, reject));
}
