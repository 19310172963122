import { queryOptions } from "@tanstack/react-query";
import { genQueries } from "src/react-query";
import { getContactFilterViews } from "src/resources/contacts.resource";

export const contactFilterViewQueries = genQueries("contact-filter-views", ({ genKey }) => ({
  filterViews: () =>
    queryOptions<any>({
      queryKey: genKey("filterViews", {}),
      queryFn: () => getContactFilterViews(),
      staleTime: 10000, // 10 secs
    }),
}));
