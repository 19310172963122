import { fetchAsObservable } from "fetcher!sofe";
import { TContact } from "src/common/types";
import { handleError } from "src/error";

export function createContactObs(body: any) {
  return fetchAsObservable("api/v2/contacts", {
    method: "POST",
    body,
  });
}

export function createContact(body: any) {
  return new Promise<{ contact: TContact }>((resolve, reject) => createContactObs(body).subscribe(resolve, reject));
}

export function updateContactObs(contactId?: string, body?: any) {
  if (!contactId || !body) return;
  return fetchAsObservable(`api/v2/contacts/${contactId}`, {
    method: "PATCH",
    body,
  });
}

export function updateContact(contactId?: string, body?: any): Promise<{ contact: TContact } | null> {
  if (!contactId || !body) return Promise.resolve(null);
  return new Promise<{ contact: TContact }>((resolve, reject) =>
    updateContactObs(contactId, body).subscribe(resolve, reject)
  );
}

export function patchContacts(body: {
  action: "archive" | "unarchive";
  search?: string;
  filters?: any;
  includes?: string[];
  excludes?: string[];
}) {
  return new Promise((resolve, reject) =>
    fetchAsObservable("api/v2/contacts", {
      method: "PATCH",
      body,
    }).subscribe(resolve, reject)
  );
}

export function deleteContact(contactId: string) {
  return new Promise((resolve, reject) => {
    fetchAsObservable(`api/v2/contacts/${contactId}`, {
      method: "DELETE",
    }).subscribe(resolve, reject);
  });
}

export type GetContactsParams = {
  page: number;
  limit: string;
  filters?: any;
  sort_data?: any;
  search?: string;
  visibleFields?: string[];
};

export function getContactsObs({ page, limit, filters, sort_data, search, visibleFields }: GetContactsParams) {
  return fetchAsObservable(`api/v2/contacts:search`, {
    method: "POST",
    body: {
      page,
      limit,
      filters,
      sort_data,
      search,
      visible_columns: visibleFields,
    },
  });
}

export function getContacts(params: GetContactsParams) {
  return new Promise((resolve, reject) => getContactsObs(params).subscribe(resolve, reject));
}

export function getContactFilterViews() {
  return new Promise((resolve, reject) => fetchAsObservable(`/api/v2/contacts/filters`).subscribe(resolve, reject));
}

export function getContactObs(contactId: string) {
  return fetchAsObservable(`api/v2/contacts/${contactId}`);
}

export function getContact(contactId: string) {
  return new Promise<TContact>((resolve, reject) => getContactObs(contactId).subscribe(resolve, reject));
}

export function createContactFilterView(body: any) {
  return fetchAsObservable(`/api/v2/contacts/filters`, {
    method: "POST",
    body: body,
  });
}

export function patchContactFilterView(viewId: string, body: any) {
  return fetchAsObservable(`/api/v2/contacts/filters/${viewId}`, {
    method: "PATCH",
    body: body,
  });
}

export function patchContactFilterViewOrder(orderedViewIds: string[]) {
  return fetchAsObservable("/api/v2/contacts/filters:reorder", {
    method: "PATCH",
    body: {
      ids: orderedViewIds,
    },
  });
}

export function deleteFilterViews(filterViewIds: string[]) {
  return fetchAsObservable(`/api/v2/contacts/filters`, {
    method: "DELETE",
    body: {
      ids: filterViewIds,
    },
  });
}

// Used to fetch the available options for conditional select filters
export function fetchSelectionOptions({
  field,
  search = "",
  fieldId = "",
}: {
  field: string;
  search?: string;
  fieldId?: string;
}) {
  const params = new URLSearchParams();
  if (field) params.append("field", field);
  if (search) params.append("search", search);
  if (fieldId) params.append("id", fieldId);
  return new Promise((resolve, reject) => {
    return fetchAsObservable(`/api/v2/contacts:filter_values?${params}`).subscribe(
      (result: any) => {
        resolve(result.values);
      },
      (err: unknown) => {
        handleError(err);
        reject();
      }
    );
  });
}
