import { queryOptions } from "@tanstack/react-query";
import { genQueries } from "src/react-query";
import { getTemplate, getTemplates } from "src/templates/templates.resource";

export const clientTemplateQueries = genQueries("client-record-templates", ({ genKey }) => ({
  template: (id: string) =>
    queryOptions<any>({
      queryKey: genKey("template", { id }),
      queryFn: () => getTemplate(id),
      staleTime: 10_000, // 10 seconds
    }),

  templates: (params: { search?: string; type?: string; page?: number }) =>
    queryOptions<any>({
      queryKey: genKey("templates", params),
      queryFn: () =>
        getTemplates({
          search: params.search || "",
          exactMatch: false,
          type: params.type || "both",
          limit: 50,
          page: params.page || 1,
        }),
      staleTime: 10_000, // 10 seconds
    }),
}));
