import { queryOptions } from "@tanstack/react-query";
import { getCustomFields } from "src/client-list/client-list.resource";
import { genQueries } from "src/react-query";

export const customFieldQueries = genQueries("custom-fields", ({ genKey }) => ({
  customFields: () =>
    queryOptions<any>({
      queryKey: genKey("custom-fields", {}),
      queryFn: () => getCustomFields(),
      staleTime: 10000, // 10 secs
    }),
}));
