import { queryOptions } from "@tanstack/react-query";
import { getClientFilterViews } from "src/client-list/client-list.resource";
import { genQueries } from "src/react-query";

export const clientFilterViewQueries = genQueries("client-filter-views", ({ genKey }) => ({
  filterViews: () =>
    queryOptions<any>({
      queryKey: genKey("filterViews", {}),
      queryFn: () => getClientFilterViews(),
      staleTime: 10000, // 10 secs
    }),
}));
